import React, { Component } from 'react';
import SystemReportList from '../../components/SystemReportList';

class SystemReport extends Component {
  render() {
    return (
      <div>
        <SystemReportList />
      </div>
    );
  }
}

export default SystemReport;
