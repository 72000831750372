import React, { Component } from 'react';
import ButtonAppBar from 'components/ButtonAppBar';

class PersonalDevelopment extends Component {
  render() {
    return (
      <div className="App">
        <ButtonAppBar />
        <p>This is text for the Personal Development Page</p>
      </div>
    );
  }
}

export default PersonalDevelopment;
